<template>
  <main id="content" role="main">

    <!-- Hero Section -->
    <div class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-12.svg') + ')'}">
      <div class="container space-top-3 space-top-lg-4 space-bottom-2 position-relative z-index-2">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto">
          <h1>beNovelty Terms of Use for FabriXAPI</h1>
          <!-- <p>Effective date: 1 January 2019</p> -->
        </div>
      </div>
    </div>
    <!-- End Hero Section -->

    <!-- Terms Content Section -->
    <div class="container space-2 space-bottom-lg-3">
      <div class="row">
        <div id="stickyBlockStartPoint" class="col-md-4 col-lg-3 mb-7 mb-md-0">
          <!-- Nav -->
          <nav
            class="js-sticky-block card"
            data-hs-sticky-block-options="{
                 &quot;parentSelector&quot;: &quot;#stickyBlockStartPoint&quot;,
                 &quot;targetSelector&quot;: &quot;#logoAndNav&quot;,
                 &quot;breakpoint&quot;: &quot;md&quot;,
                 &quot;startPoint&quot;: &quot;#stickyBlockStartPoint&quot;,
                 &quot;endPoint&quot;: &quot;#stickyBlockEndPoint&quot;,
                 &quot;stickyOffsetTop&quot;: 24,
                 &quot;stickyOffsetBottom&quot;: 24
               }"
          >
            <div class="card-body">
              <ul class="js-scroll-nav nav nav-sm nav-x-0 flex-column">
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#platform">1. Use of the Platform</a>
                  <!-- <ol class="navbar-nav">
                    <li class="nav-item">
                      <a class="nav-link mb-2" href="#personal-data">A. Personal Data that we collect about you</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link mb-2" href="#information">B. Information that we collect automatically on our Sites</a>
                    </li>
                  </ol> -->
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#representation">2. Representations and Warranties</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#liability">3. Liability for Content</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#changes">4. Changes to the Platform, Accounts and Pricing</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#warranty">5. No Warranty</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#termination">6. Termination</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#majeure">7. Force Majeure</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#waiver">8. Waiver</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#general">9. General</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link font-weight-bold mb-2" href="#communication">10. Communication</a>
                </li>

              </ul>
            </div>
          </nav>
          <!-- End Nav -->
        </div>

        <div class="col-md-8 col-lg-9">
          <div id="intro" class="">
            <!-- Title -->
            <div class="mb-3">
              <h2 class="h3">Welcome to beNovelty Limited (beNovelty, "we" "our", or "us")</h2>
            </div>
            <!-- End Title -->

            <p>Important. Please read these Terms of Service carefully.</p>
            <p>These Terms of Services govern your use of beNovelty.com (the "Website"), our mobile and desktop apps (our "Apps") and all related players, widgets, tools, applications, data, software, APIs and other services provided by beNovelty Limited (the "Services").</p>
            <p>These Terms of Services, together with our Privacy Policy are legally binding agreement (the "Agreement") between you and beNovelty in relation to your use of the Website, Apps and Services (together, the "Platform").</p>
          </div>

          <!-- 1. Use of the Platform -->
          <div id="platform" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>1. Use of the Platform</h3>
            </div>
            <!-- End Title -->
            <p>beNovelty licenses its Platform pursuant to these terms of services. Your use of this Platform are conditioned on your acceptance of this Terms of Services, and by using / reading this website you agree to be bound by these Terms of Services.</p>
          </div>
          <!-- End 1. Use of the Platform -->

          <!-- 2. Representations and Warranties -->
          <div id="representation" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>2. Representations and Warranties</h3>
            </div>
            <!-- End Title -->
            <p>You hereby represent and warrant to the Platform as follows:</p>
            <ol>
              <li class="pb-2">Your Content, and each and every part thereof, is an original work by you, or you have obtained all rights, licenses, consents and permissions necessary in order to use, and (if and where relevant) to authorise the Platform to use, Your Content pursuant to these Terms of Services, including, without limitation, the right to upload, reproduce, store, transmit, distribute, share, display, make available and otherwise communicate Your Content via the Website and other mobile devices for your assigned users/ audiences.</li>
              <li class="pb-2">Your Content and the availability thereof on the Platform does not and will not infringe or violate the rights of any third party, including, without limitation, any intellectual property rights, performers' rights, rights of privacy or publicity, or rights in confidential information.</li>
              <li class="pb-2">You have obtained any and all necessary consents, permissions and/or releases from any and all persons appearing in Your Content in order to include their name, voice, performance or likeness in Your Content and to publish the same on the Platform and via any Linked Services.</li>
              <li class="pb-2">Your Content, including any comments that you may post on the Website, is not and will not be unlawful, offensive, abusive, libellous, defamatory, obscene, racist, sexually explicit, ethnically or culturally offensive, indecent, will not promote violence, terrorism, or illegal acts, or incite hatred on grounds of race, gender, religion or sexual orientation.</li>
              <li class="pb-2">Your Content does not and will not create any liability on the part of beNovelty, its subsidiaries, affiliates, successors, and assigns, and their respective employees, agents, directors, officers and/or shareholders.</li>
              <li class="pb-2">Your Content and Your Content Records may be passed to third parties. However, Your Content records are regarded as confidential and therefore will not be divulged to any third party, other than our employees and if legally required to do so to the appropriate authorities.</li>
              <li class="pb-2">You have the right to request sight of, and copies of any and all Your Content Records we keep, on the proviso that we are given reasonable notice of such a request. You are requested to retain copies of any literature issued in relation to the provision of our services. Where appropriate, we shall issue Yours with appropriate written information, handouts or copies of records as part of an agreed consent, for the benefit of both parties.</li>
            </ol>
            <p>beNovelty reserves the right to remove Your Content, suspend or terminate your access to the Platform and/or pursue all legal remedies if we believe that any of Your Content breaches any of the foregoing representations or warranties, or otherwise infringes another person's rights or violates any law, rule or regulation.</p>
          </div>
          <!-- End 2. Representations and Warranties -->

          <!-- 3. Liability for Content -->
          <div id="liability" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>3. Liability for Content</h3>
            </div>
            <!-- End Title -->
            <p>You hereby acknowledge and agree that the Platform does not review the Content created or uploaded by its users, and neither beNovelty Limited nor its subsidiaries, affiliates, successors, assigns, employees, agents, directors, officers and shareholders has any obligation, and does not undertake or assume any duty, to monitor the Platform for Content that is inappropriate, that does or might infringe any third party rights, or has otherwise been uploaded in breach of these Terms of Services or applicable law.</p>
            <!-- Title -->
            <div id="exclusions" class="mb-3">
              <h4>A. Exclusions and Limitations</h4>
            </div>
            <!-- End Title -->
            <p>The information on this web site is provided on an "as is" basis. To the fullest extent permitted by law, this Company:</p>
            <ul>
              <li class="pb-2">excludes all representations and warranties relating to this website and its contents or which is or may be provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in this website and/or the Company's literature; and</li>
              <li class="pb-2">excludes all liability for damages arising out of or in connection with your use of this website. This includes, without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect, consequential and incidental damages.</li>
            </ul>
            <p>This Company does not however exclude liability for death or personal injury caused by its negligence. The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.</p>
            <!-- Title -->
            <div id="data" class="mb-3">
              <h4>B. Data Protection, Privacy and Cookies</h4>
            </div>
            <!-- End Title -->
            <p>All personal data that you provide to us in connection with your use of the Platform is collected, stored, used and disclosed by beNovelty Limited in accordance with our Privacy Policy. In addition, in common with most online services, we use cookies in Google Analytics to help us understand how people are using the Platform, (closed beta to test products or services) and Mailchimp to provide inquiries service, so that we can continue to improve the service we offer. By accepting these Terms of Services and using the Platform, you also accept the terms of the Privacy Policy.</p>
            <!-- Title -->
            <div id="data" class="mb-3">
              <h4>C. Links to this website</h4>
            </div>
            <!-- End Title -->
            <p>You may not create a link to any page of this website without our prior written consent. If you do create a link to a page of this website you do so at your own risk and the exclusions and limitations set out above will apply to your use of this website by linking to it.</p>
            <!-- Title -->
            <div id="data" class="mb-3">
              <h4>D. Links from this website</h4>
            </div>
            <!-- End Title -->
            <p>We do not monitor or review the content of other party's websites which are linked to from this website. Opinions expressed or material appearing on such websites are not necessarily shared or endorsed by us and should not be regarded as the publisher of such opinions or material. Please be aware that we are not responsible for the privacy practices, or content, of these sites. We encourage our users to be aware when they leave our site & to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through this site yourself, before disclosing any personal information to them. This Company will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.</p>
          </div>
          <!-- End 3. Liability for Content -->

          <!-- 4. Changes to the Platform, Accounts and Pricing -->
          <div id="changes" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>4. Changes to the Platform, Accounts and Pricing</h3>
            </div>
            <!-- End Title -->
            <p>beNovelty Limited reserves the right at any time and for any reason to suspend, discontinue, terminate or cease providing access to the Platform or any part thereof, temporarily or permanently, and whether in its entirety or with respect to individual territories only. In the case of any temporary or permanent suspension, discontinuation, termination or cessation of access, beNovelty Limited shall use its reasonable endeavors to notify registered users of such decision in advance.</p>
            <p>You hereby agree that beNovelty Limited and its subsidiaries, affiliates, successors, assigns, employees, agents, directors, officers and shareholders shall not be liable to you or to any third party for any changes or modifications to the Website, Apps and/or any Services that beNovelty Limited may wish to make from time to time, or for any decision to suspend, discontinue or terminate the Website, the Services or any part or parts thereof, or your possibility to use or access the same from or within any territory or territories.</p>
            <p>The Platform may change the features of any type of account, may withdraw or, or introduce new features, products or types of account at any time and for any reason, and may change the prices charged for any of its Subscriptions from time to time. In the event of any increase in the price or material reduction in the features of any Subscription which you have purchased, such change(s) will be communicated to you and will only take effect with respect to any subsequent renewal of your subscription. In all other cases, where beNovelty Limited proposes to make changes to any type of Subscription you have purchased, and these changes are material and to your disadvantage, beNovelty Limited will notify you of the proposed changes by sending a message to your Platform account and/or an email to the then current email address that we have for your account, at least one (1) week in advance. You will have no obligation to continue using the Platform following any such notification, but if you do not terminate your account as described in the Termination section below during such one (1) week period, your continued use of your account after the end of that week period will constitute your acceptance of the changes to your Subscription.</p>
          </div>
          <!-- End 4. Changes to the Platform, Accounts and Pricing -->

          <!-- 5. No Warranty -->
          <div id="warranty" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>5. No Warranty</h3>
            </div>
            <!-- End Title -->
            <p>beNovelty Limited does not warrant that any of Your Videos, the Platform's assets, the Platform, or the Services will meet Your needs or requirements or be error-free or always available or available at any particular time or that any errors or defects will be corrected.</p>
          </div>
          <!-- End 5. No Warranty -->

          <!-- 6. Termination -->
          <div id="termination" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>6. Termination</h3>
            </div>
            <!-- End Title -->
            <p>You may terminate this Agreement at any time by sending notice to info@benovelty.com confirming such termination, by removing all of Your Content from your account, by deleting your account and thereafter by ceasing to use the Platform. If you have a Subscription, and terminate this Agreement before the end of your such Subscription, we are unable to offer any refund for any unexpired period of your Subscription. beNovelty Limited may suspend your access to the Platform and/or terminate this Agreement at any time if (i) you are deemed to be a Repeat Infringer; (ii) you are in breach of any of the material provision of these Terms of Services (iii) beNovelty Limited elects at its discretion to cease providing access to the Platform in the jurisdiction where you reside or from where you are attempting to access the Platform, or (iv) in other reasonable circumstances as determined by beNovelty Limited at its discretion.</p>
            <p>If you have a Subscription and your account is suspended or terminated by beNovelty Limited pursuant to above description, you will not be entitled to any refund for any unexpired period of your subscription.</p>
            <p>beNovelty Limited may terminate this Agreement for any reason or for no reason by providing you 30 days' notice as set forth below.</p>
          </div>
          <!-- End 6. Termination -->

          <!-- 7. Force Majeure -->
          <div id="majeure" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>7. Force Majeure</h3>
            </div>
            <!-- End Title -->
            <p>Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to an event beyond the control of such party including but not limited to any Act of God, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood or any other natural or man made eventuality outside of our control, which causes the termination of an agreement or contract entered into, nor which could have been reasonably foreseen. Any Party affected by such event shall forthwith inform the other Party of the same and shall use all reasonable endeavours to comply with the terms and conditions of any Agreement contained herein.</p>
          </div>
          <!-- End 7. Force Majeure -->

          <!-- 8. Waiver -->
          <div id="waiver" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>8. Waiver</h3>
            </div>
            <!-- End Title -->
            <p>Failure of either Party to insist upon strict performance of any provision of this or any Agreement or the failure of either Party to exercise any right or remedy to which it, he or they are entitled hereunder shall not constitute a waiver thereof and shall not cause a diminution of the obligations under this or any Agreement. No waiver of any of the provisions of this or any Agreement shall be effective unless it is expressly stated to be such and signed by both Parties.</p>
          </div>
          <!-- End 8. Waiver -->

          <!-- 9. General -->
          <div id="general" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>9. General</h3>
            </div>
            <!-- End Title -->
            <p>The laws of England and Wales Hong Kong law governs these terms and conditions. (By accessing this website [and using our services/buying our products] you consent to these terms and conditions and to the exclusive jurisdiction of the English Hong Kong courts in all disputes arising out of such access.) If any of these terms are deemed invalid or unenforceable for any reason (including, but not limited to the exclusions and limitations set out above), then the invalid or unenforceable provision will be severed from these terms and the remaining terms will continue to apply. Failure of the Company to enforce any of the provisions set out in these Terms and Conditions and any Agreement, or failure to exercise any option to terminate, shall not be construed as waiver of such provisions and shall not affect the validity of these Terms and Conditions or of any Agreement or any part thereof, or the right thereafter to enforce each and every provision. These Terms and Conditions shall not be amended, modified, varied or supplemented except in writing and signed by duly authorised representatives of the Company.</p>
          </div>
          <!-- End 9. General -->

          <!-- 10. General -->
          <div id="communication" class="pt-11">
            <!-- Title -->
            <div class="mb-3">
              <h3>10. Communication</h3>
            </div>
            <!-- End Title -->
            <p>Our contact information, can be found on our Contact Us link on our website or via Company literature or via the Company's stated telephone, facsimile or mobile telephone numbers.</p>
            <p>The use of the terms "includes" and "including", and similar terms, shall be deemed not to limit what else might be included. Please e-mail to info@benovelty.com if you have any questions.</p>
          </div>
          <!-- End 10. Communication-->

          <!-- Sticky Block End Point -->
          <div id="stickyBlockEndPoint" />
        </div>
      </div>
    </div>
    <!-- End Terms Content Section -->

  </main>
</template>

<script>
const $ = require('jquery')
import HSStickyBlock from '../../assets/vendor/hs-sticky-block/src/js/hs-sticky-block'
export default {
  name: 'TermsOfUse',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
    $('.js-sticky-block').each(function() {
      new HSStickyBlock($(this)).init()
    })
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Terms of Use for FabriXAPI | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/terms-of-use' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Terms of Use for FabriXAPI | beNovelty' },
        { property: 'og:description', content: 'Welcome to beNovelty. By using this Site you agree to be bound by the terms and conditions stated here.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/terms' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
ol {
  list-style: block;
  padding-inline-start: 40px;
}
</style>
